<template>
  <!--中酿学堂二级分类-->
  <div class="container">
    <van-sticky>
      <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
    </van-sticky>

    <!--导航-->
    <!-- <van-grid :gutter="10" :column-num="2" :route="true">
      <van-grid-item
        class="mygriditem"
        v-for="(nav, index) in classListOption"
        :key="index"
        v-bind:icon="nav.imageUrl"
        v-bind:text="nav.text"
        v-bind:to="nav.value"
      />
    </van-grid> -->

    <div class="van-clearfix">
      <div
        class="layout_wrapper"
        style=""
        v-for="(nav, index) in classListOption"
        @click="toDetail(nav.value)"

        :key="index"
      >
        <div class="schoolitem">
          <div
            style="width: 100% display: table;
    clear: both;"
          >
            <div class="itemtext">
              {{ nav.text }}
            </div>
          </div>
          <div
            style="width: 100% display: table;
    clear: both;"
          >
            <div class="itemtag">
              <van-tag round color="#9d1d22">了解详情</van-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getClassList } from "@/api/school";

export default {
  data() {
    return {
      title: "中酿学堂",
      classListOption: [],
    };
  },

  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    toDetail(path){
      this.$router.push({
        path: path,
        
      });
    },
    async initData() {
      let sClassList = await getClassList();

      sClassList.data.data.map((x) =>
        this.classListOption.push({
          text: x.name,
          value: "/course/list?id=" + x.id,
          imageUrl: x.imageUrl,
        })
      );
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.layout_wrapper {
  height: 70px;
  margin: 5px 0px;
  float: left;
  width: 50%;
  overflow: hidden;
  /deep/ .schoolitem {
    height: 100%;
    width: 93%;
    margin-left: 10px;
    background-image: url("/image/xtback.png");
    background-size: 100% 100%;
  }
  /deep/ .itemtext {
    height: 30px;
    float: right;
    padding-top: 7px;
    padding-right: 11px;
  }
  /deep/.itemtag {
    height: 30px;
    float: right;
    padding-right: 35px;
  }
}

.mygriditem {
  background-image: url("/image/xtback.png");

  /deep/ img {
    width: 100%;
    height: 50px;
    max-height: 50px;
  }
}
</style>